import "./hero.scss"

import React from "react"
import backArrow from '../../../static/images/icon-back-arrow.svg'
import heroImage from '../../../static/images/sys-int-detail/sys-int-hero.svg'
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

export default (props) => {
    const staticContent = props.staticContent;
    const staticImages = props.staticImages;
    const service = props.service;

    return (
        <section className="bg-gradient services_info">
            <div className="link-bar">
                <a href="/services" className="return-link row"><img className="back-arrow" alt="return arrow for link" src={backArrow}/>BACK TO SERVICES</a>
            </div>
            <div className="row middle-md my-auto">
                <div className="rows-center">
                    <div className="col-xs-12 col-md-6 align-items-center">
                    <h1 className="text-center service-title">{service.title}</h1>
                        <p className="text-center">{service.body}</p>
                    </div>
                    <div className="col-xs-12 col-md-6 align-items-center">
                        <img 
                            id="illustration"
                            src={getStaticImageByIdentifier('SI.hero.image', staticImages)}
                            alt="Illustration of a person mapping data on an idea board"
                            className="hero-illustration"
                        />
                    </div>
                </div>
            </div>
            <div className="bg-light">
                <div className="row middle-md padding-t-3">
                    <div className="col-xs-12 col-md-12 align-items-center">
                        <h2 className = "text-center">{getStaticContentByIdentifier('SI.subtitle1', staticContent)}</h2>
                        <p className = "text-center">{getStaticContentByIdentifier('SI.content1', staticContent)} </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

