import Cardlists from "../../components/cardlists"
import Cta from "../../components/cta"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Hero from "../../components/sysint/hero"
import Layout from "../../components/layout"
import React from "react"
import Seo from "../../components/seo2"
import { getSEOByIdentifier } from "../../services/seo.service";
import ServiceLinks from '../../components/services/serviceLinks'
import { getStaticContentByIdentifier } from "../../services/misc.service";

const service = 3;

const SysIntPage = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const serviceArray = props.pageContext.services;
  const cardlists = props.pageContext.cards;
  const SEOInfo = props.pageContext.SEOInfo;

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('system-integration', SEOInfo)}/>
      <Header />
      <Hero service={serviceArray[service]} staticContent={staticContent} staticImages={staticImages}/>
      <Cardlists cardlists={cardlists} />
      <ServiceLinks  pos={service} serviceArray={serviceArray}/>
      <Cta title={getStaticContentByIdentifier('SI.CTA.content', staticContent)} button={getStaticContentByIdentifier('SI.CTA.button', staticContent)} />
      <Footer />
    </Layout>
  )
}

export default SysIntPage;
